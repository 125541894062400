<script setup lang="ts">

</script>

<template>
    <section class="w-partners">

        <h5 class="w-partners__title">Our Partners</h5>

        <div class="w-partners__container">
            <div class="w-partners__sponsor">
                <a href="https://theterracestore.com/search?type=product&q=blackburn">
                    <img src="https://theterracestore.com/cdn/shop/files/TEXT-logo.jpg?v=1688742569&width=380" alt="The Terrace"
                         class="w-partners__sponsor-image" />
                </a>
                <span class="w-partners__sponsor-title">The Terrace</span>
            </div>
            <div class="w-partners__sponsor">
                <a href="mailto:media@brfcs.com?subject=Sponsorship%20Enquiry">
                    <img src="/images/BRFCS_logo.svg"
                         class="w-partners__sponsor-image" />
                </a>
                <span class="w-partners__sponsor-title">Your Name Here?</span>
            </div>
        </div>


    </section>
</template>

<style scoped lang="scss">
@use "#/utilities/mixins" as m;

.w-partners {
    $this: &;
    background: var(--g-color-system-white);
    padding: 4.8rem 2.4rem;
    width: 100%;

    &__title {
        @include m.h6-bold;
        text-align: center;
        color: var(--g-color-system-dark);
        text-transform: uppercase;
        margin-bottom: 2.4rem;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 4.8rem;
        justify-content: space-evenly;
    }

    &__sponsor {
        display: flex;
        gap: .8rem;
        align-items: center;
        flex-direction: column;
    }

    &__sponsor-image {
        width: 10rem;
        height: 10rem;
        object-fit: contain;
    }

    &__sponsor-title {
        @include m.xsm-bold;
        text-transform: uppercase;
    }

    @media (width < m.$mobile) {
        s &__sponsor-image {
            width: 8rem;
            height: 8rem;
            object-fit: contain;
        }
    }
}
</style>
